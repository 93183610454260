<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
@import "~@/assets/css/account.css";
</style>
<style type="text/css" scoped>
:deep(.el-form-item__content) {
  line-height: inherit;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination__jump {
  margin-left: 0;
  margin-right: 10px;
}
.queryForm .el-select,.queryForm .el-input{
  width: 150px !important;
}
.check-distri {
  line-height: 32px;
}
.el-tag{
  cursor: pointer;
}
/deep/ .el-dialog__header {
  background: rgb(197 197 197 / 40%) !important;
  padding: 20px;
}

</style>
<script>
import Layout from "@/views/layouts/main";
import {
  listPage,
  infoerMationEdit,
  findUser,
  info,
  removeUser,
  removeFile,
  delInformation,
  memberList, forward
} from "@/api/admin/information/informationCollaboration.js";
import InformationDownload from "@/components/information-download.vue";
import {checkFile, getUploadLimit, uploadFiles} from "@/api/common/public";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    InformationDownload,
  },
  data() {
    return {
      wjlx:[
          '公告文件','考试数据','数据样张'
      ],
      query: {
        keyword: '',
        yxzt: '',
      },
      uploadModule: 'Information',
      uploadLimit:{},
      items: [{
        text: "用户首页",
        href: "/admin"
      },
        {
          text: "基础大类",
          active: true
        }
      ],
      exportTitle: "考试类别导出",
      showmodal: false,
      showmodalAdd: false,
      dialogTitle: "添加考试类别",
      module: "KSDL",
      tableList: [],
      allDataList: [],
      addForm: {
        lbbm: "",
        lbmc: "",
        lbsx: "资格类",
        qyzt: true,
        pxh: 0,
      },
      pageData: {
        pageNum: 1,
        pageSize: 50,
        total: 0
      },
      isIndeterminate: false,
      checkList: [],
      memberShow: false,
      memberList: [],
      memberSearch: {},
      isShow: false,
      imgCode: {
        uuid: "",
        img: "",
        code: "",
      },
      verification: "",
      committingPhoneCode: false,
      phoneCode: '',
      uploadLimit:{},
      rules: {
        wjbt: [
          {required: true, message: '请输入文件标题', trigger: 'blur'},
          {min: 1, max: 100, message: '长度在 100 个字符以内', trigger: 'blur'}
        ],
        wjlx: [
          {required: true, message: '请选择文件类型', trigger: 'blur'},
        ],
        wjms: [
          {min: 1, max: 500, message: '长度在 500 个字符以内', trigger: 'blur'}
        ],
        wjsxx: [
          {required: true, message: '请选择文件时效性', trigger: 'blur'},
        ],
        fssj: [
          {required: true, message: '请选择发送时间', trigger: 'blur'},
        ],
      },
      userInfo: {}
    };
  },
  methods: {
    // 删除
    deleteItem(obj) {
      var checkboxes = document.querySelectorAll('.checkRow');
      let list = []
      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked) {
          list.push(checkbox.value)
        }
      });
      if(obj.infoId && list.length) {
        return;
      }
      if(obj.infoId) {
        this.$confirm(`是否确认删除【${obj.wjbt}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation( [obj.infoId]).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }else {
        this.$confirm(`是否确认批量删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation(list).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }
    },
    // 获取数据列表
    getList() {
      listPage({...this.query, ...this.pageData}).then(res => {
        if (res.status) {
          this.tableList = res.data
          this.pageData.total = res.total
        }
      })
    },

    editItem(data, flag) {
      this.addForm = {}
      this.showmodal = true
      this.dialogTitle = "查看信息协同"
      info(data.infoId).then(res => {
        if(res.status) {
          this.addForm = res.data
          this.addForm.flag = flag
        }
      })
    },
    submitforward() {
      forward(this.addForm).then(res => {
          if (res.status) {
            this.showmodal = false;
            this.getList();
            this.$message({
              type: "success",
              message: '转发成功',
            });
          }
      });
    },
    editItemUser(data) {
      this.addForm = {}
      this.showmodalAdd = true
      this.dialogTitle = "修改信息协同"
      info(data.infoId).then(res => {
        if(res.status) {
          this.addForm = res.data
        }
      })
    },
    submit(send) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          infoerMationEdit({...this.addForm, send: send}).then(res => {
            if (res.status) {
              this.showmodalAdd = false;
              this.getList();
              this.$message({
                type: "success",
                message: this.addForm.infoId?'修改成功':'添加成功',
              });
            }
          });
        }
      })
    },

    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },

    handleUserClose(data) {
      if(!data.memberId){
        this.addForm.userList = this.addForm.userList.filter(f => { return f.sjrzh!=data.sjrzh})
        return;
      }
      this.$confirm(`是否确认删除”${data.sjr}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUser(this.addForm.infoId, data.memberId).then((res) => {
          if (res.status) {
            this.addForm.userList = this.addForm.userList.filter(f => { return f.sjrzh!=data.sjrzh})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleFileClose(data) {
      this.$confirm(`是否确认删除文件”${data.wjm}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeFile(this.addForm.infoId, data.fileId).then((res) => {
          if (res.status) {
            this.addForm.fileList = this.addForm.fileList.filter(f => { return f.fileId!=data.fileId})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    addUser(){
      if(!this.addForm.yddhStr) {
        return;
      }
      findUser({yddh: this.addForm.yddhStr}).then(res => {
        if(res.status) {
          res.data = res.data.map(m => {
            return {infoId: this.addForm.infoId||null, sjrzh: m.yhzh, sjr: m.xm, yddh: m.yddh}
          })
          this.addForm.userList = [...this.addForm.userList||[], ...res.data]
          this.addForm.userList = this.addForm.userList.filter((item, index, self) =>
                  index === self.findIndex(t => (
                      t.sjrzh === item.sjrzh
                  ))
          );
          this.$forceUpdate()
        }
      })
    },
    isDateExpired(dateString) {
      // 将字符串日期转换为Date对象
      // 假设dateString的格式为"YYYY-MM-DD"
      const dateParts = dateString.split('-');
      const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // 注意月份是从0开始的

      // 获取当前日期（不包含时间部分）
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 将当前时间设置为当天的开始

      // 比较两个日期
      return date >= now;
    },
    // 全选
    checkAll() {
      var checkboxes = document.querySelectorAll('.checkRow');
      checkboxes.forEach(function(checkbox) {
        checkbox.checked = document.getElementById('all').checked;
      });
    },
    openMember(item){
      this.memberShow = true;
      this.memberSearch.infoId = item.infoId
      this.memberSearch.keyword = ''
      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    searchMember() {
      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    open(){
      this.isShow = true
      this.phoneCodeShow = false
      this.phoneCode = ''
      this.verification = ''
    
    },
    isDateWithinValidityPeriod(dateStr, daysToAdd) {
      if(!dateStr) {
        return;
      }
      // 将字符串转换为 Date 对象
      const startDate = new Date(dateStr);
      // 检查日期字符串是否有效
      if (isNaN(startDate.getTime())) {
          throw new Error('Invalid date string');
      }
      // 加上指定的天数
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + daysToAdd);
      
      // 获取当前日期
      const currentDate = new Date();
      
      // 判断当前日期是否在有效期内
      const isValid = currentDate <= endDate;
      
      return isValid;
    },
    addItem() {
      this.showmodalAdd = true
      this.dialogTitle = "发起信息协同"
      this.addForm = {
        fileList: [],
        memberList: []
      }
    },
    
    uploadFile(file) {
      /** 上传背景文件 */
      var _this = this;
      file = file.raw;
      
      // 检查是否已经上传了文件
      if (this.addForm.fileList && this.addForm.fileList.length > 0) {
        this.$message({
          type: "warning",
          message: "只能上传一个文件,请先删除已有文件"
        });
        return;
      }
      
      if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
        _this.uploadStatus = "loading";
        uploadFiles(_this.uploadModule, file).then(res => {
          if (res.status) {
            var file = res.data[0];
            if (file.status) {
              this.addForm.fileList = [{...file, wjm: file.name, wjdz: file.fileId, bclj: file.path, wjmm: file.password}];
              this.addForm.wjmm = file.password;
              _this.$message({
                type: "success",
                message: "上传成功"
              });
              this.fileUp = true
            } else {
              _this.$message({
                type: "error",
                message: file.error
              });
            }
          }
          _this.uploadStatus = "";
        });
      }
    },
    limit() {
      getUploadLimit({
        module: this.uploadModule,
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
    },
    
    // 删除
    deleteItem(obj) {
     
      this.$confirm(`是否确认删除【${obj.wjbt}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delInformation( [obj.infoId]).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: '删除成功',
            });
            this.getList();
          }
        });
      }).catch(() => {

      });
      
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getList();
    this.limit();
  }

};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList queryForm">
                <el-input class=" mr-2 " placeholder="查询标题"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                  class="iconfont el-icon-circle-plus-outline mr-2 font-size-20"></i>发送文件</b-button>

              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all" @change="checkAll" /> 序号</th>
                  <th>标题</th>
                  <th style="width: 8%;">文件类型</th>
                  <th style="width: 10%;">发送人</th>
                  <th style="width: 150px;">发送时间</th>
                  <th style="width: 8%;">文件时效性</th>
                  <th style="width: 120px;">转发</th>
                  <th style="width: 120px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">

                  <td>
                    <input type="checkbox" name="infoId" :value="obj.infoId" class="checkRow"/>
                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.wjbt}}</td>
                  <td>{{obj.wjlx}}</td>
                  <td> {{obj.fsr}}</td>
                  <td> {{obj.fssj}}</td>
                  <td :style="{color:isDateExpired(obj.yxsj)||obj.wjsxx == 0?'blue':'red'}"> {{obj.wjsxx == 0 ?'长期有效':obj.yxsj}}{{obj.wjsxx == 0 ?'':'前'}}</td>
                 <td>
                      <template v-if="isDateWithinValidityPeriod(obj.fssj, obj.wjsxx)" >
                        <span style="cursor: pointer"  @click='editItem(obj, false)'>下载</span>
                        &emsp;
                        <span style="cursor: pointer"  @click='editItem(obj, true)'>转发</span>
                      </template>
                      <span v-else style="color: red;">已过期</span>
                 </td>
                  <td class="tab-icon">
                    <template  v-if="obj.createUser == userInfo.yhzh">
                      <i class="iconfont icon-edit-two align-middle mr-1"
                                          @click='editItemUser(obj)'></i>&emsp;
                    <i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                                                                        @click="deleteItem(obj)"></i>
                                                                        
                    </template> 
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <!-- 添加-->
    <el-dialog id="addClass" :visible.sync="showmodalAdd" :close-on-click-modal="false" :title="this.dialogTitle" no-close-on-backdrop title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" size="mini" label-position="right" label-width="100px" :rules="rules" :model="addForm">
          <el-form-item prop="wjlx" label="文件类型：">
            <el-radio-group size="small" v-model="addForm.wjlx">
              <el-radio-button :label="item" :key="item" v-for="item in wjlx">{{ item }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="wjbt" label="文件标题：">
              <el-input size="small" style="width: 100%;" maxlength="100" placeholder="输入文件标题"
                       v-model="addForm.wjbt" />
          </el-form-item>
          <el-form-item prop="wjms" label="简要描述：">
              <el-input size="small" type="textarea" rows="4" style="width: 100%;margin-bottom: 10px;" maxlength="500" placeholder="简要描述" v-model="addForm.wjms" />
          </el-form-item>
          <el-form-item prop="fssj" label="发送时间：">
              <el-date-picker
                  v-model="addForm.fssj"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="选择发送时间"
                  default-time="12:00:00">
              </el-date-picker>
          </el-form-item>
          <el-form-item prop="wjsxx" label="时 效 性：">
                <el-radio-group size="small" v-model="addForm.wjsxx"  >
<!--                  <el-radio-button label="0">长期有效</el-radio-button>-->
                  <el-radio-button label="15">15天</el-radio-button>
                  <el-radio-button label="30">30天</el-radio-button>
                </el-radio-group>
          </el-form-item>
          <el-form-item label="添加用户：" style="padding-bottom: 5px;">
                <el-input v-model="addForm.yddhStr" type="textarea" maxlength="1000" style="width: 80%;" placeholder="输入手机号码，多个用英文逗号分开"></el-input>
                <el-button size="mini" style="margin-left: 10px;" @click="addUser"><i class="iconfont mr-1 el-icon-user"></i>添加用户</el-button>
          </el-form-item>
          <el-form-item label="发送用户：">
              <div v-if="addForm.userList&&addForm.userList.length">
                <el-tag
                    v-for="(user, index) in addForm.userList"
                    :key="index"
                    class="mb-2 mr-2"
                    type="success"
                    closable
                    @close="handleUserClose(user)"
                >
                  {{ user.sjr }}
                </el-tag>
              </div>
          </el-form-item>
          <el-form-item label="上传文件：">
                <el-upload class="upload-demo" style="width: 100%;text-align: left;" action="" :auto-upload="false" :on-change="uploadFile" size="small"
                           :accept="uploadLimit.ext" :show-file-list="false">
                  <el-button size="mini" plain style="display: inline;"><i
                      class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                  <el-tag type="danger" class=" ml-2" style="height: 30px;">
                    支持上传{{ uploadLimit.ext }}文件，且不超过{{
                      uploadLimit.size / 1024 / 1024
                    }}M</el-tag>
                </el-upload>
          </el-form-item>
          <el-form-item label="已传文件：">
                <div v-if="addForm.fileList && addForm.fileList.length">
                  <el-tag
                      v-for="(file, index) in addForm.fileList"
                      :key="index"
                      class="mb-2"
                      type="info"
                      @click="openFile(file.wjdz)"
                      closable
                      @close="handleFileClose(file)"
                  >
                    {{ file.wjm }} (密码：{{ addForm.wjmm }})
                  </el-tag>
                </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center">
        <button type="button" class="btn btn-info h30 w-md mr-3" @click="submit(0)">保存提交</button>

        <button type="button" class="btn btn-info h30 w-md mr-3" style="border-color:  #67c23a;background-color: #67c23a;" @click="submit(1)">保存提交并发送短信通知</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodalAdd = false">取消</button>
      </div>



    </el-dialog>
    <el-dialog id="addClass" :visible.sync="showmodal" :close-on-click-modal="false" :title="this.dialogTitle" no-close-on-backdrop title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件标题：</label>
              <div class=" col-sm-10 p-0 flexList">
                {{addForm.wjbt}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>简要描述：</label>
              <div class="col-sm-10  p-0">
                {{addForm.wjms}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件类型：</label>
              <div class="col-sm-10  p-0">
                {{addForm.wjlx}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>发布时间：</label>
              {{ addForm.fssj }}
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">时&ensp;效&ensp;性：</label>
              <div class="col-sm-10 p-0 ">
                <el-radio-group size="small" :disabled="true" v-model="addForm.wjsxx"  >
<!--                  <el-radio-button label="0">长期有效</el-radio-button>-->
                  <el-radio-button label="15">15天</el-radio-button>
                  <el-radio-button label="30">30天</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item  v-if="addForm.flag" >
            <div class="d-flex check-distri mb-3"><label>转&emsp;&emsp;发：</label>
              <div style="width: 80%;">
                <el-input v-model="addForm.yddhStr" type="textarea" maxlength="1000" style="width: 80%;" placeholder="输入手机号码，多个用英文逗号分开"></el-input>
                <el-button size="mini" style="margin-left: 10px;" @click="addUser"><i class="iconfont mr-1 el-icon-user"></i>添加用户</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>转发用户：</label>
              <div v-if="addForm.userList&&addForm.userList.length">
                <el-tag
                    v-for="(user, index) in addForm.userList"
                    :key="index"
                    class="mb-2 mr-2"
                    type="success"
                    closable
                    @close="handleUserClose(user)"
                >
                  {{ user.sjr }}
                </el-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>下载文件：</label>
              <div class="col-sm-10 p-0 ">
                <div v-if="addForm.fileList && addForm.fileList.length">
                  <el-tag
                      v-for="(file, index) in addForm.fileList"
                      :key="index"
                      class="mb-2"
                      type="info"
                      title="点击下载"
                      @click="open()"
                  >
                    {{ file.wjm }}
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="addForm.flag" class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
                                              @click="submitforward">确认转发</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>
    </el-dialog>
    <el-dialog id="imgCheck"  :visible.sync="isShow"  title="下载文件"
               top="38vh" width="400px"
             hide-footer>
          <InformationDownload :info-id="addForm.infoId" />
    </el-dialog>
  </Layout>
</template>
